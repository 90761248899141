.nav-item .nav-link:hover,
.nav-item .nav-link:active,
.nav-item .nav-link:focus {
  background-color: transparent;
  color: inherit;
  outline: none;
  box-shadow: none;
}

table {
  font-size: 0.9rem;
}

.alight-right {
  text-align: right;
}

.last-row > td {
  background-color: #D9EDF7 !important;
  text-align: left;
  font-weight: bold;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure the spinner is above all other elements */
}

.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 9998; /* Ensure the backdrop is just below the spinner */
}
